import './App.css';
import NavBar from './components/Navbar';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useState, useEffect } from 'react';
import '@wfp/ui/src/globals/scss/styles.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import FilterShops from "./components/Shops/FilterShops";
import FilterMTOs from "./components/MTOs/FilterMTOs";
import FilterEssnMTOs from "./components/EssnMTOs/FilterEssnMTOs";
import FilterSites from "./components/Sites/FilterSites";
import FilterATMs from "./components/ATMs/FilterATMs";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

function App() {

  const [lngUsed, setLngUsed]= useState("ar");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("ar");
    document.documentElement.dir = 'rtl';
    setLngUsed('ar');
  }, [i18n]);

  const lngs = {
    en: {nativeName: 'English' },
    ar: {nativeName: 'العربية'}
  }

  function changeLanguage (lng) {
    i18next.changeLanguage(lng);
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
    setLngUsed(lng)
  };
  
  return (
    <div className="App">
        <NavBar lngs={lngs}
                changeLanguage={changeLanguage}
                shopHeaderName={t('shopHeaderTitle')}
                mtoHeaderName={t('mtoHeaderTitle')}
                essnMtoHaderName={t('essnMtoHeaderTitle')}
                sitesHeaderName={t('sitesHeaderTitle')}
                atmsHeaderName={t('atmHeaderTitle')}
                pageName1={t('shopPage')}
                pageName2={t('mtoPage')}
                pageName3={t('essnMtoPage')}
                pageName4={t('sitesPage')}
                pageName5={t('atmPage')}
        />
        <Routes>
          <Route path="/" element={<Navigate  to="/mtos" />} />
          <Route path="/mtos" element={<FilterMTOs 
                                          placeHolderName1={t('placeHolder1')}  
                                          placeHolderName2={t('placeHolder2')}
                                          mtosStatment={t('mtosDisplayedStatment')}
                                          alertMessage={t('filteringMessage')}
                                          usedLanguage={lngUsed}
                                          dataLoad={t('dataLoad')}
                                          loading={t('loading')}
                                          location={t('location')}
                                      />} />
          <Route path="/shops" element={<FilterShops 
                                          placeHolderName1={t('placeHolder1')}  
                                          placeHolderName2={t('placeHolder2')}
                                          shopsStatment={t('shopsDisplayedStatment')} 
                                          alertMessage={t('filteringMessage')}
                                          usedLanguage={lngUsed}
                                          dataLoad={t('dataLoad')}
                                          loading={t('loading')}
                                          location={t('location')}
                                          bannerMessage={t('bannerMessage')}
                                          popUpTitle={t('popUpTitle')}
                                      />} />
          <Route path="/essnMtos" element={<FilterEssnMTOs 
                                          placeHolderName1={t('placeHolder1')}  
                                          placeHolderName2={t('placeHolder2')}
                                          essnMtosStatment={t('essnMtosDisplayedStatment')}
                                          alertMessage={t('filteringMessage')}
                                          usedLanguage={lngUsed}
                                          dataLoad={t('dataLoad')}
                                          loading={t('loading')}
                                          location={t('location')}
                                      />} />
          <Route path="/sites" element={<FilterSites 
                                          placeHolderName1={t('placeHolder1')}  
                                          placeHolderName2={t('placeHolder2')}
                                          sitesStatement={t('sitesDisplayedStatment')}
                                          alertMessage={t('filteringMessage')}
                                          usedLanguage={lngUsed}
                                          dataLoad={t('dataLoad')}
                                          loading={t('loading')}
                                          location={t('location')}
                                      />} />
          <Route path="/atms" element={<FilterATMs 
                                          placeHolderName1={t('placeHolder1')}  
                                          placeHolderName2={t('placeHolder2')}
                                          atmsStatement={t('atmsDisplayedStatment')}
                                          alertMessage={t('filteringMessage')}
                                          usedLanguage={lngUsed}
                                          dataLoad={t('dataLoad')}
                                          loading={t('loading')}
                                          location={t('location')}
                                      />} />
      </Routes> 
    </div>
  );  
}

export default App;
