import React, { useEffect, useState } from "react";
import "../CommonComponentsAndStyling/Display.css";
import icon from "../../images/disabilityIcon.png";
import { getAllAtms, getAtmByDistrict, getAtmByVillage, getBaseUrl } from "../../api";
import InfiniteScroll from 'react-infinite-scroll-component';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

export default function ATMs(props) {
  const { usedLanguage } = props;
  const [atms, setAtms] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalAtms, setTotalAtms] = useState(0); 
  const [districtCode, setDistrictCode] = useState(null);

  const fetchData = async (reset) => {
    try {
      
      let page = 1;

      if(!reset) {
        page = currentPage;
      }

      let data = [];

      let atmsList = [];

      if(props.districtCode && !props.villagePcode) {
        atmsList = await getAtmByDistrict(props.districtCode, page);
      } else if(props.villagePcode) {
        atmsList = await getAtmByVillage(props.villagePcode, page);
      } else {
        atmsList = await getAllAtms(page);
      }

      if(atmsList.last_page > page)  {
        setCurrentPage(page + 1);
      } else {
        setHasMoreData(false);
      }

      if(reset) {
        data = atmsList.data;
      } else {
        data = [...atms, ...atmsList.data];
      }

      setTotalAtms(atmsList.total);
      setAtms(data);
      setDistrictCode(props.districtCode);
      
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    setHasMoreData(true);
    setCurrentPage(1);

    fetchData(true);
  }, [props.districtCode, props.villagePcode]);

  function getTranslation(translations, locale, field) {
    const translation = translations.find(t => t.locale === locale);
    return translation ? translation[field] : '';
  }

  const lengthStatment =`${props.atmsStatement} ${totalAtms}`;
  
  return (

    
    <div className="container">      
    <div className="container-sub">
      <h6 className='num'>{lengthStatment}</h6>
      <hr className="line" />
      <InfiniteScroll
        dataLength={atms.length}
        next={fetchData}
        hasMore={hasMoreData}
        loader={<p className="load" >{props.loading}</p>}
        endMessage={<p className="load" >{props.dataLoad}</p>}
        >
      <Row className="rowStyle">
            {atms.map((atm, index) => (
              <div className="col-md-5">
                <Card key={index} className="divStyle">
                  <Card.Header className="cardHeader">
                    <Row className="no-gutters align-items-center">
                      <Col  xs={3} className="p-0">
                        <img src={getBaseUrl() + "storage/" + atm.image_path} className="img" alt="atm"/>
                      </Col>
                      <Col xs={8} className="p-0">
                        <Card.Title className="name">{getTranslation(atm.translations, usedLanguage, 'name')}</Card.Title>
                        <Card.Text className="shopId">{atm.term_id}</Card.Text>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <div className="disabilityDiv">
                      <Card.Text className="details">{getTranslation(atm.district.translations, usedLanguage, 'name')} - {getTranslation(atm.village.translations, usedLanguage, 'name')}</Card.Text>
                      {atm.is_accessible === 1 && <img alt="" src={icon} className="icon"/>}
                    </div>
                      <Card.Text className="details">{getTranslation(atm.translations, usedLanguage, 'address')}</Card.Text>
                      <Card.Text className="details">{getTranslation(atm.translations, usedLanguage, 'working_hours')}</Card.Text>
                  </Card.Body>
                  <a  href={`https://www.google.com/maps/search/?api=1&query=${atm.latitude},${atm.longitude}`} target="_blank">
                    <Card.Footer className="cardFooter">
                      <h6 className="buttonText">
                        {props.location}
                      </h6>
                    </Card.Footer>
                  </a>
                </Card>
                </div>
              ))} 
      </Row>
      </InfiniteScroll>
    </div>
    </div>
  );
}
